.footer{
    background: #0C1859;
    padding: 50px;
}
.footer h3{
    color: white;
    font-family: 'Anton', sans-serif;
}
.footer ul{
    color:#00A6EB;
}
.footer ul li {
    list-style-type: none;
    margin-bottom: 5px;
}
.footer ul.lineas{
    padding-inline-start: 20px
}
.footer ul{
    padding-inline-start: 0px
}
.footer ul.lineas li {
    list-style-type: none;
    list-style-image: url('./flecha.png');
    border-bottom: #083582 solid 1px;
}
.postfooter{
    padding: 20px 0;
}
.cfacebook{
    color: #0E4096
}
.cinstagram{
    color: #00A6EB
}
.cyoutube{
    color: #E60004
}
.cwhatsapp{
    color: #98C000
}

footer a{
    color: #00A6EB
}